<!-- @Author: Yu_Bo -->
<template>
    <div class='ai_comics'>
        <div class="ai_comics_banner">
            <div class="banner_l">
                <div class="tit">
                    AI换脸
                </div>
                <div class="desc">
                    开启异次元之旅
                </div>
            </div>
            <div class="banner_r">
                <p class="text">剩余漫画次数：<span class="num">{{ cartoon }}</span>次</p>
                <div class="btn" @click="toBuy">订阅</div>
            </div>
        </div>
        <div class="ai_comics_main mt20">
            <div class="ai_comics_main_l">
                <upload-comics ref="uploadImg" @uploadSuccess="uploadSuccess" :option="option"></upload-comics>
            </div>
            <div class="ai_comics_main_r">
                <div class="tit">相似度:</div>
                <div class="slider_box mt14">
                    <div class="text">{{ reference_rate }}%</div>
                    <div class="slider">
                        <el-slider v-model="reference_rate" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="ai_title mt20">
                    <span>截取画面</span>
                    <el-tooltip placement="right">
                        <div slot="content">
                            不同尺寸的会有不同的漫画效果
                        </div>
                        <img src="@/assets/images/workben/question.png" alt="">
                    </el-tooltip>
                </div>
                <div class="proportion_list mt14">
                    <div class="proportion_item" :class="{ active: item.value == proportion }"
                        v-for="item in proportionList" :key="item.value" @click="proportion = item.value">
                        <div class="block">
                            <img :src="item.label" alt="">
                        </div>
                        <div class="text">{{ item.value }}</div>
                    </div>
                </div>
                <div class="ai_title">
                    <span>生成数量</span>
                    <el-tooltip placement="right">
                        <div slot="content">
                            作品数量即消耗的漫画次数
                        </div>
                        <img src="@/assets/images/workben/question.png" alt="">
                    </el-tooltip>
                </div>
                <div class="mt14">
                    <el-input-number v-model="num" controls-position="right" :min="0" :max="10"></el-input-number>
                </div>
                <div class="ai_bottom mt30">
                    <div class="bottom_btn" @click="draw">
                        生成漫画 ·<span>消耗{{ num }}次</span>
                    </div>
                </div>
            </div>
        </div>
        <loading ref="loading" :type="'1'" @comicsParents="comicsParents"></loading>
        <comics-success ref="comicsSuccess" @resetPage="resetPage" @reCreate="reCreate"></comics-success>
        <no-draw-times ref='dialogDraw' :title="'您还没有漫画次数'" :type="3" @renovate="getParams"></no-draw-times>
    </div>
</template>

<script>
import Loading from '../components/loading/index.vue' //加载
import ComicsSuccess from '../components/comics_success/index.vue'  //成功
import NoDrawTimes from '@/components/trial_expiration/no_draw_times.vue'//没有绘画次数
import UploadComics from '@/components/upload/uploadComics.vue'//上传

export default {
    components: { Loading, ComicsSuccess, NoDrawTimes, UploadComics },
    props: {},
    data() {
        return {
            num: 1,  //选择数量
            reference_rate: 10,
            proportionList: [],
            proportion: '自定义',
            reference_pic: '',
            cartoon: 0, // 余额
            option: {
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                // autoCropWidth: 708, // 默认生成截图框宽度
                // autoCropHeight: 410, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: false, // 是否开启截图框宽高固定比例
                fixedNumber: [], // 截图框的宽高比例
                full: false, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: true, // 上传图片按照原始比例渲染
                centerBox: false, // 截图框是否被限制在图片里面
                infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                canMove: true,
            },
            files: '',

        }
    },
    computed: {},
    watch: {
        proportion(nVal, oVal) {
            if (nVal != '自定义') {
                this.option.fixed = true
                let fixedNumber = nVal.split(':')
                this.option.fixedNumber = [Number(fixedNumber[0]), Number(fixedNumber[1])]
            } else {
                this.option.fixedNumber = []
                this.option.fixed = false
            }
        }
    },
    created() {
        this.getParams()
    },
    mounted() { },
    methods: {
        // 成功弹窗
        comicsParents() {
            // this.$refs.comicsSuccess.open()
        },
        // 绘制
        draw() {
            if (this.cartoon >= this.num) {
                this.createComics()
            } else {
                this.noNumber()
            }
        },
        // 生成中  加载弹窗
        async createComics() {
            await this.$refs.uploadImg.dataURLtoFile()
        },
        submit() {
            if (!this.reference_pic) {
                this.$errMsg('请先上传漫画图片')
                return
            }
            const data = {
                num: this.num,   // 生成数量 最大10
                reference_pic: this.reference_pic, // 参考图片 （用不含域名的 地址 对应 short_url）
                reference_rate: this.reference_rate, // 参考图片相似度
                proportion: this.proportion, // 画面大小
                order_source: 1, //订单来源 1 PC 2 APP
            }
            this.$refs.loading.open()
            this.$creationApi.addCartoon(data).then(res => {
                if (res.code == 1000) {
                    this.$refs.loading.close()
                    this.$refs.comicsSuccess.open(res.result)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 没有次数
        noNumber() {
            this.$refs.dialogDraw.open()
        },
        uploadSuccess(val) {
            this.reference_pic = val
            this.submit()
        },
        getParams() {
            this.$creationApi.getDrawParams().then(res => {
                if (res.code == 1000) {
                    this.cartoon = res.result.balance.cartoon

                } else {
                    this.$errMsg(res.message)
                }
            })
            this.$creationApi.getCartoonParams().then(res => {
                if (res.code == 1000) {
                    this.proportionList = res.result.proportion

                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 新增 重置页面
        resetPage() {
            this.num = 1   // 生成数量 最大10
            this.originality = '' // 创意描述
            this.reference_pic = '' // 参考图片 （用不含域名的 地址 对应 short_url）
            this.reference_rate = 10 // 参考图片相似度
            this.proportion = 0 // 画面大小
        },
        // 重新生成
        reCreate() {
            this.getParams()
            this.draw()
        },
        toBuy() {
            this.noNumber()
        }
    },
}
</script>

<style lang='scss' scoped>
.ai_comics {
    min-width: 1774px;

    .ai_comics_banner {
        width: 100%;
        height: 140px;
        display: flex;
        justify-content: space-between;
        padding: 0 200px 0 50px;
        background: url(~@/assets/images/workben/banner2.png) no-repeat 100% / cover;
        border-radius: 10px;

        .banner_l {
            .tit {
                font-size: 32px;
                font-weight: 500;
                color: #ffffff;
                line-height: 45px;
                padding-top: 33px;
            }

            .desc {
                font-size: 14px;
                color: #ffffff;
                line-height: 20px;
                padding-top: 10px;
            }
        }

        .banner_r {
            padding: 0 14px;
            height: 50px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 25px;
            margin-top: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
                font-size: 14px;
                color: #ffffff;
                line-height: 20px;
                padding-right: 16px;

                .num {
                    font-size: 24px;
                }
            }

            .btn {
                width: 56px;
                height: 30px;
                background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
                border-radius: 15px;
                display: flex;
                text-align: center;
                justify-content: center;
                cursor: pointer;
                color: #ffffff;
                font-size: 14px;
                line-height: 30px;
            }
        }
    }

    .ai_comics_main {
        width: 100%;
        height: 660px;
        background: #ffffff;
        border-radius: 10px;
        padding: 30px;
        display: flex;

        .ai_comics_main_l {
            width: 708px;
            margin-right: 120px;

            .upload_video {
                width: 708px;
                height: 410px;
                background: #f5f7fa;
                border-radius: 4px;
                border: 1px dashed #dddfe6;
                margin-right: 40px;
                cursor: pointer;
                position: relative;

                .upload_img {
                    width: 24px;
                    height: 24px;
                    margin: 170px auto 0;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .upload_text {
                    font-size: 14px;
                    color: #2e4bf2;
                    line-height: 20px;
                    text-align: center;
                    padding-top: 6px;
                }

                .empower {
                    position: absolute;
                    width: 70px;
                    height: 20px;
                    font-size: 14px;
                    color: #2e4bf2;
                    line-height: 20px;
                    text-decoration: underline;
                    bottom: 0;
                    right: -76px;
                }
            }
        }

        .ai_comics_main_r {
            width: 708px;

            .tit {
                font-size: 14px;
                color: #666666;
                line-height: 20px;
            }

            .slider_box {
                width: 756px;
                height: 56px;
                background: #f5f7fa;
                border-radius: 4px;
                padding: 0 20px;
                display: flex;
                align-items: center;

                .text {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2e4bf2;
                    line-height: 20px;
                    padding-right: 10px;
                }

                .slider {
                    width: 650px;
                }
            }

            .proportion_list {
                display: flex;
                flex-wrap: wrap;
                width: 450px;

                .proportion_item {
                    width: 80px;
                    height: 30px;
                    background: rgba(188, 188, 188, 0.1);
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    cursor: pointer;

                    .block {
                        width: 15px;
                        height: 15px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .intercept {
                        width: 12px;
                        height: 12px;

                        img {
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .text {
                        font-size: 12px;
                        color: #999999;
                        padding-left: 4px;
                    }
                }

                .proportion_item.active {
                    background: rgba(46, 75, 242, 0.1);

                    .text {
                        color: #2e4bf2;
                    }
                }
            }

            .ai_bottom {
                display: flex;
                justify-content: flex-end;

                .bottom_btn {
                    width: 156px;
                    height: 38px;
                    background: linear-gradient(90deg,
                            #4875ff 0%,
                            #2b4afe 100%);
                    border-radius: 2px;
                    line-height: 38px;
                    text-align: center;
                    font-size: 14px;
                    color: #ffffff;
                    cursor: pointer;

                    span {
                        font-size: 12px;
                        opacity: 0.6;
                        padding-left: 6px;
                    }
                }
            }
        }
    }
}

.ai_title {
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    display: flex;
    align-items: center;

    span {
        padding-right: 6px;
    }

    img {
        width: 14px;
        height: 14px;
    }
}

.mt14 {
    margin-top: 14px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}
</style>