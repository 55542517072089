<template>
    <div class="upload_img">
        <div :style="[allstyle]">
            <el-upload v-if="!option.img" class="avatar-uploader" action="#" :show-file-list="false"
                :http-request="uploadimg">
                <!-- <img v-if="url" :src="url" class="avatar"> -->
                <div class="avatar-uploader-icon">
                    <i class="el-icon-upload2"></i>
                    <p>本地上传</p>
                </div>
            </el-upload>
            <div v-else class="cover-box">
                <vueCropper ref="cropper" style="background-image:none" :img="option.img" :outputSize="option.outputSize"
                    :outputType="option.outputType" :info="option.info" :canScale="option.canScale"
                    :autoCrop="option.autoCrop" :autoCropWidth="option.autoCropWidth"
                    :autoCropHeight="option.autoCropHeight" :fixed="option.fixed" :fixedBox="option.fixedBox"
                    :fixedNumber="option.fixedNumber"></vueCropper>
            </div>
        </div>
        <el-upload class="upload-demo" action="#" :show-file-list="false" :http-request="uploadimg">
            <el-link size="small" type="primary" style="width: 70px">重新上传</el-link>
        </el-upload>
    </div>
</template>
<script>
import { VueCropper } from 'vue-cropper'

export default {
    components: {
        VueCropper,
    },
    props: {
        width: {
            type: String,
            default: '708px'
        },
        height: {
            type: String,
            default: '410px'
        },
        imageUrl: {
            type: String,
            default: ''
        },
        option: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            url: '',
            is_img: ['image/gif', 'image/png', 'image/jpg', 'image/jpeg'],
            result: {},
        }
    },
    computed: {
        allstyle() {
            return {
                '--uploadW': this.width,
                '--uploadH': this.height,
            }
        },
    },
    watch: {
        'option.fixedNumber': {
            handler() {
                if (this.option.img) {
                    this.$nextTick(() => {
                        this.$refs.cropper.goAutoCrop()
                    });
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        // 上传图片
        uploadimg(item) {
            var self = this
            // 限制图片格式
            const isJPG = this.is_img.includes(item.file.type)
            // 限制图片大小
            const isLt2M = item.file.size / 1024 / 1024 <= 3;
            if (!isJPG) {
                self.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
            }
            if (!isLt2M) {
                self.$errMsg('上传图片大小不能超过 2MB!');
            }
            if (isJPG && isLt2M) {
                if (item.file) {
                    this.imageToBase64(item.file)
                    // this.$nextTick(() => {

                    //     console.log(this.$refs.cropper)
                    // })
                }
            }
        },
        imageToBase64(file) {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                this.result = reader.result
                this.option.img = this.result
            }
            reader.onerror = function (error) {
                console.log('Error: ', error)
            }
        },
        dataURLtoFile() {
            this.$refs.cropper.getCropData(data => {
                var blobs = this.dataURLtoBlob(data)
                var files = this.blobToFile(blobs, Date.parse(new Date()) + '.png')
                let newFile = new FormData()
                newFile.append('file', files);
                this.$creationApi.uploadDraw(newFile).then(res => {
                    if (res.code == 1000) {
                        this.$emit('uploadSuccess', res.result.short_url)
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            })
        },
        //1,先将base64转换为blob
        dataURLtoBlob(dataurl) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        //2,再将blob转换为file
        blobToFile(theBlob, fileName) {
            theBlob.lastModifiedDate = new Date();  // 文件最后的修改日期
            theBlob.name = fileName;                // 文件名
            return new File([theBlob], fileName, { type: theBlob.type, lastModified: Date.now() });
        },
    }
}
</script>
<style lang="scss" scoped>
.upload_img {
    display: flex;
    align-items: flex-end;

    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #2e4bf2;
    }

    .avatar-uploader-icon {
        width: var(--uploadW);
        height: var(--uploadH);
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px dashed #dddfe6;
        text-align: center;
        color: #2e4bf2;
        padding-top: calc((410px / 2) - 44px);

        i {
            font-size: 32px;
            font-weight: 500;
            height: 32px;
        }

        p {
            font-size: 12px;
            height: 12px;
        }
    }

    .avatar {
        width: var(--uploadW);
        height: var(--uploadH);
        display: block;
    }

    .cover-box {
        width: 708px;
        height: 410px;
        background-color: #f4f4f6;
        border: 1px dashed #d9d9d9;

    }
}
</style>